import React from 'react';
import mailLogo from './../../assets/images/mail_logo.png';
import style from './constuction.module.scss';

export const Construction = () => {
  return (
    <div className={style['construction']}>
      <div className={style['title']}>ECHELLE Creative Consulting</div>
      <div className={style['construction-text']}>
        <span>Site on Construction</span>
        <span className={style['separator']}> - </span>
        <span>ホームページ作成中</span>
      </div>
      <div className={style['construction-mail']}>
        <span className={style['mail-container']}>
          <img className={style['mail-logo']} src={mailLogo} />
          <a className={style['mail-text']} href="mailto:fiona@echelle.co.jp">
            fiona@echelle.co.jp
          </a>
        </span>
      </div>
    </div>
  );
};
