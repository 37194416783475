import React from 'react';

export const DirectionToLeft = 'toLeft' as const;
export const DirectionToRight = 'toRight' as const;

export type Direction = typeof DirectionToLeft | typeof DirectionToRight;

export type DefaultProps = {
  setDirection: React.Dispatch<React.SetStateAction<Direction>>;
};
