import React, { useState } from 'react';
import { RestContextContainer } from '@karpeleslab/klb-react-services';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import { useTranslation } from 'react-i18next';
import { Direction, DirectionToLeft } from './types/common';
import { Construction } from './components/Construction/Construction';

const Page = (): JSX.Element => {
  const { t } = useTranslation();
  const [direction, setDirection] = useState<Direction>(DirectionToLeft);

  const location = useLocation();
  return (
    <ThemeProvider theme={theme}>
      <Switch></Switch>
      <Construction />
    </ThemeProvider>
  );
};

export const Routing = (): JSX.Element => {
  return (
    <RestContextContainer>
      <Page />
    </RestContextContainer>
  );
};
